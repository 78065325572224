/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();


  const currentUrl = window.location.href;
  const splitCurrentUrl = currentUrl.split("/");
  const currentPage = splitCurrentUrl[4];

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);

    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader, currentPage]);

  //date now
  let dateNow = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "Asia/Jakarta",
    })
  );

  let tgl = dateNow.getDate();
  

  function handleSubHeader(header) {
    switch (header) {
      case "budgets":
        return "";
      case "approval":
        return "Approval";
      case "transfer":
        return localStorage.getItem("id_role") === "4" ||
          localStorage.getItem("id_role") === "7" ||
          localStorage.getItem("id_role") === "8" ||
          localStorage.getItem("id_role") === "15" ? (
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              Transfer Budget
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>
                <Link
                  to="/budget-submission/inter-brand"
                  style={{ color: "black" }}
                >
                  Transfer Inter Brand
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link
                  to="/budget-submission/cross-brand"
                  style={{ color: "black" }}
                >
                  Transfer Cross Brand
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          ""
        );
      case "inter-brand":
        return "inter-brand";
      case "cross-brand":
        return "cross-brand";
      case "preview-inter-brand":
        return "";
      case "create":
        return "";
      case "activities":
        return localStorage.getItem("id_role") === "4" ||
          localStorage.getItem("id_role") === "7" ||
          localStorage.getItem("id_role") === "13" ||
          localStorage.getItem("id_role") === "15" || 
          localStorage.getItem("id_role") === "16"? (
            ((tgl >= 28 && tgl <= 31)) ? (
            <button className="btn btn-outline-danger">
              Activity cannot be accessed on frezee periode
            </button>
          ) : (
            <button className="btn btn-primary">
              <Link
                className="text-white"
                to="/activity-submission/createactivities"
              >
                Request activity
              </Link>
            </button>
          )
        ) : (
          ""
        );

      case "list-of-user":
        return (
          <button className="btn btn-primary">
            <Link className="text-white" to="/user-management/adduser">
              Add User
            </Link>
          </button>
        );
      default:
        return "";
    }
  }

  function handleSubHeaderRight(header) {
    switch (header) {
      case "budgets":
        return "";
      case "approval":
        return "Approval";
      case "transfer":
        return "";
      case "inter-brand":
        return "inter-brand";
      case "preview-inter-brand":
        return "preview-inter-brand";
      case "create":
        return (
          <div className="d-flex">
            {/* <span className="btn btn-outline-primary mr-2">Save as draft</span>
						<a className="btn btn-primary" href="#">Next</a> */}
          </div>
        );
      case "list-of-user":
        return (
          <a
            type="button"
            className="btn btn-outline-primary"
            // href="/budget-submission/importnewbudget"
            //onClick={customersUIProps.newCustomerButtonClick}
          >
            <i className="fas fa-file-import mr-2"></i>
            <span>Import</span>
          </a>
        );
      default:
        return "";
    }
  }

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <span>{handleSubHeader(currentPage)}</span>

          {/* {subheader.title === "Approval" ? (
						<span>ini approval</span>
					) : (
							<button className="btn btn-primary">
								<Link className="text-white" to="/budget/create">
									Create Budget
							</Link>
							</button>
						)} */}
        </div>

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          {handleSubHeaderRight(currentPage)}
          {/* <QuickActions /> */}
        </div>
      </div>
    </div>
  );
}
